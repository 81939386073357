<!--右侧广告选组件-->
<template>
  <div class="ContBox">
		<div class="miniSearchBox selfSearchBox">
			<input
				@keyup.enter="search()"
				type="text"
				v-model="key"
				placeholder="请输入搜索内容"
			/>
			<div
				@click="search()"
				class="dfc miniSearchButton bg2"
			>
				<img src="@/assets/image/icon/search.png" alt="" />
			</div>
		</div>
		<div class="contactWay">
			<div class="wayTitle">{{competitionForm.title}}
				<span class="decorate"></span>
			</div>
			<div class="wayDetail" v-html="competitionForm.contact_detail"></div>
		</div>
		<div class="advertisingItem" v-for="item in sideAD" :key="item.id" @click="jump(item.url)">
			<img :src="item.src" alt="">
		</div>
  </div>
</template>

<script>
import {
	getToken
} from "@/utils/auth";
import {
	DesignCompetitionGetContactDetail
} from "../../../Api";
export default {
  name: "advertising",
  props: ["sideAD", "getListParams"],
  data() {
    return {
			key: '',
			competitionForm: {
				title: "",	// 大赛标题
				contact_detail: '',
			},
		}
  },
  created() {
		if(this.$route.query.search) {
			this.key = this.$route.query.search
		}
		
		DesignCompetitionGetContactDetail({token: getToken()}).then((res)=>{
			let detailData = res.data
			if (detailData) {
				this.competitionForm = detailData
			}
		})
	},
	methods: {
		search() {
			if(this.$route.name == "CompetitionIndex") {
				this.$emit("changeSearch", this.key)
				//将参数设置到地址栏
				const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
				query.search = this.key;
				this.$router.push({ path: this.$route.path, query });
			} else {
				const { href } = this.$router.resolve({
					path: "/competition/index",
					query: { search: this.key },
				});
				window.open(href, "_blank");
			}
		},
		jump(url) {
			if(url) {
				window.open(url, '_blank')
			}
		}
	},
  mounted() {},
  destroyed() {},
	watch: {},
};
</script>

<style scoped>
.bg1 {
	background: #f46600;
}
.bg2 {
	background: #040404;
}
.miniSearchButton {
	width: 0.8rem;
	height: 0.4rem;
	cursor: pointer;
}
.miniSearchButton img {
	width: 0.2rem;
	height: 0.2rem;
}
.miniSearchBox {
	width: 4rem;
	height: 0.4rem;
	border-radius: 0.04rem;
	overflow: hidden;
	display: flex;
	align-items: center;
	background: #ffffff;
}

.selfSearchBox {
	width: 100%;
}
.miniSearchBox input {
	padding-left: 0.15rem;
	height: 0.4rem;
	flex: 1;
}
.miniSearchBox input::-webkit-input-placeholder {
	color: #bfbfbf;
	font-size: 0.12rem;
}
.contactWay {
	width: 3.6rem;
	height: 2.58rem;
	border-radius: 0.08rem;
	background-color: #fff;
	padding: 0.16rem;
	margin: 0.24rem 0;
	display: flex;
	flex-direction: column;
}

.wayTitle {
	width: 100%;
	white-space: pre-wrap;
	position: relative;
	margin-bottom: 0.26rem;
	font-size: 0.16rem;
	font-weight: bold;
	color: rgba(244, 102, 0, 1);
}
.decorate {
	position: absolute;
	bottom: -0.12rem;
	left: 0;
	width: 0.16rem;
	height: 0.02rem;
	border-radius: 0.02rem;
	background: rgba(244, 102, 0, 1);
}
.decorate::after{
	position: absolute;
	top: 0;
	left: 0.22rem;
	content: "";
	width: 0.08rem;
	height: 0.02rem;
	border-radius: 0.02rem;
	background: rgba(244, 102, 0, 1);
}
.decorate::before{
	position: absolute;
	top: 0;
	left: 0.36rem;
	content: "";
	width: 0.08rem;
	height: 0.02rem;
	border-radius: 0.02rem;
	background: rgba(244, 102, 0, 1);
}
.wayDetail {
	flex: 1;
	overflow-y: auto;
	overflow-wrap: break-word;
}
.wayDetail >>> img {
	max-width: 100%;
}
.wayRow {
	width: 3.28rem;
	margin-bottom: 0.1rem;
	font-size: 0.14rem;
	font-weight: 400;
	color: rgba(56, 56, 56, 1);
}
.advertisingItem {
	width: 3.6rem;
	border-radius: 0.08rem;
	margin-bottom: 0.24rem;
	overflow: hidden;
}
.advertisingItem img{
	width: 3.6rem;
	object-fit: cover;
}
</style>