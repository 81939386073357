<!--大赛列表组件-->
<template>
	<div ref="box">
		<el-skeleton
				style="width: 100%"
				:loading="loading"
				animated
				:count="list.length + 4"
		>
			<template slot="template">
				<el-skeleton-item
						variant="image"
						class="competitionItems"
				/>
			</template >
			<div v-if="list.length > 0 || loading">
				<div style="width: 100%;display: flex;justify-content: space-between;flex-wrap: wrap;" class="competitionList">
					<router-link
							:to="{ path: '/competition/detail', query: { detailId: item.id } }"
							target="_blank"
							class="competitionItem"
							v-for="(item, index) in list"
							:key="index"
					>
						<div class="competitionItemImg">
							<img :src="item.img" alt="" />
						</div>
						<div class="competitionItemBottom">
							<div class="competitionItemBottomLfte">
								<div class="competitionItemTitle txt-over">{{ item.title }}</div>
								<div class="competitionItemTime">
									征集时间：{{item.levy_start_time | formDate("yyyy-MM-dd")}} - {{item.levy_end_time | formDate("yyyy-MM-dd")}}
								</div>
							</div>
							<div v-if="item.state == 1" class="competitionItemBut activeClass">
								未开始
							</div>
							<div v-else-if="item.state == 2" class="competitionItemBut active">
								正在征集中
							</div>
							<div v-else-if="item.state == 3" class="competitionItemBut">
								已结束
							</div>
						</div>
					</router-link>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
					<div style="height: 0; width: 3.2rem"></div>
				</div>
				<p class="t_c mt20 loadImg" :class="{ load: isLoading }">
					<img src="@/assets/image/loading.gif" alt="" />
				</p>
				<p
					class="t_c mt20 loadImg"
					:class="{ load: !isLoading && !listStatus.isData }"
				>
					没有更多了...
				</p>
			</div>
			
			<div v-else>
				<el-empty description="没有内容哦"></el-empty>
			</div>
		</el-skeleton>
	</div>
</template>

<script>
export default {
  name: "CompetitionList",
  props: {
    competitionList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
		listStatus: {
			type: Object,
			default: () => {
				return {
					isData: true,	// 判断是否有下一页
					isNull: false	// 判断是否为空
				}
			}
		}
  },
	data() {
		return {
			list: [],
			isLoading: false,
		};
	},
	methods: {
		// 清除数据
		clear() {
			this.list = []
		},
		// 添加数据
    pushData() {
      this.list = this.list.concat(this.competitionList);
      this.isLoading = false;
    },
		// 翻页
		loadmore() {
			if (this.listStatus.isNull || !this.listStatus.isData) return;
			this.$emit("nextPage");
		},
		// 判断滚动位置
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const windowHeight =
				document.documentElement.clientHeight || document.body.clientHeight;
			const offsetTop = this.$refs.box.offsetHeight;
			if (scrollTop + windowHeight >= offsetTop && !this.isLoading) {
				//到了这个就可以进行业务逻辑加载后台数据了
				if (this.listStatus.isNull || !this.listStatus.isData) return;
				this.isLoading = true;
				this.loadmore();
			}
		},
	},
	mounted() {
		this.pushData();
		window.addEventListener("scroll", this.handleScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	watch: {
		competitionList() {
			this.pushData();
		},
	},
};
</script>

<style scoped>
.loadImg {
  visibility: hidden;
	height: 0;
  opacity: 0;
  transition: 0.5s;
}
.loadImg.load {
	height: auto;
	margin:0 0 0.2rem;
  visibility: visible;
  opacity: 1;
}
::v-deep .is-animated {
	width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.competitionList {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.competitionItemBottom {
	width: 100%;
	height: 0.8rem;
	padding: 0.12rem 0.16rem;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
}
.competitionItemBottomLfte {
	width: calc(100% - 1.4rem);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.competitionItemTitle {
	/* width: calc(100% - 1.4rem); */
	width: 100%;
	font-size: 0.18;
	font-weight: 400;
	color: rgba(30, 32, 35, 1);
}
.competitionItemTime {
	width: 100%;
  font-size: 0.14rem;
	line-height: 0.32rem;
  font-weight: 400;
  color: rgba(30, 32, 35, 0.59);
}
.competitionItemBut {
	height: 0.26rem;
	padding: 0.03rem 0.12rem;
	border-radius: 0.13rem;
	background: rgba(239, 244, 246, 1);
	font-size: 0.14rem;
	color: rgba(166, 166, 166, 1);
	align-self: flex-end;
}
.active {
	color: #fff;
	background: rgba(255, 195, 0, 1);
}
.activeClass {
	color: #fff;
	background: rgba(30, 32, 35, 1);
}
.competitionItem {
	background-color: #fff;
	width: calc(50% - 0.2rem);
	/* height: 4.1rem; */
	margin-bottom: 0.4rem;
	border-radius: 0.12rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}

.competitionItems {
	background-color: #fff;
	width: calc(50% - 0.2rem);
	height: 4.1rem;
	margin-bottom: 0.4rem;
	border-radius: 0.12rem;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: translateY(0);
  -webkit-transform: translateY(0);
}
.competitionItem:hover {
  transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
  -moz-transform: translateY(-6px);
}

.competitionItemImg {
  border-radius: 0.08rem;
  overflow: hidden;
}
.competitionItemImg img {
  width: 100%;
  /* height: 3.3rem; */
	object-fit: cover;
}
</style>