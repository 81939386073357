<template>
	<div class="competitionBox">
    <SlideShow :bannerList="bannerList"></SlideShow>
		<CompetitionClassifyMenu @changeMenu="changeMenu" :menuList="menuList" :isUpAdmin="isUpAdmin" :getListParams="getListParams"></CompetitionClassifyMenu>
		<div class="midBox">
			<div class="contentBox">
				<CompetitionList 
					ref="list"
					@nextPage="nextPage"
					:competitionList="competitionList"
					:listStatus="listStatus"
					:loading="loading">
				</CompetitionList>
			</div>
			<div class="rightBox">
				<div class="xiding">
					<advertising :sideAD="sideAD" @changeSearch="changeSearch"></advertising>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import SlideShow from "./componts/SlideShow.vue";
import advertising from "./componts/advertising.vue";
import CompetitionList from "./componts/CompetitionList.vue";
import CompetitionClassifyMenu from "./componts/CompetitionClassifyMenu.vue";
import {
	getBanner,
	getDesignMenu,
	DesignCompetitionGetList,
	DesignCompetitionCheckPermissions
} from '@/Api'
import {
	getToken
} from "@/utils/auth";
export default {
  name: 'CompetitionIndex',
  data() {
    return {
			isUpAdmin: false,
			getListParams: {
				id: '',
				page: 0,
				per_page: 8,
				key: '',
				start_time: '',
				end_time: ''
			},
			menuList: [],
      bannerList: [],
			sideAD: [],
			listStatus: {
				isData: true,
				isNull: false,
			},
			competitionList: [],
			loading: false
    }
  },
  components: {
    SlideShow,
		CompetitionClassifyMenu,
		advertising,
		CompetitionList
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
  },
  created() {
    this.initData()
		// 获取权限
		DesignCompetitionCheckPermissions({token: getToken()}).then((res)=>{
			if (res.data.error === 0) {
				this.isUpAdmin = true
			} 
		})
  },
  methods: {
		// 修改搜索条件
		changeMenu(res) {
			this.listStatus= {
				isData: true,
				isNull: false,
			}
      this.$refs.list && this.$refs.list.clear();
			this.getListParams = res;
			this.getListParams.page = 1
		},
    //下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		changeSearch(key) {
			if (this.loading) return
			this.listStatus= {
				isData: true,
				isNull: false,
			}
      this.$refs.list && this.$refs.list.clear();
			this.getListParams.page = 1
			this.getListParams.key = key;
		},
		getList() {
			this.loading = true;
			let params = JSON.parse(JSON.stringify(this.getListParams));
			DesignCompetitionGetList(params).then(res => {
				if (res.status === '200') {
					this.loading = false;
					const { data } = res;
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
					this.competitionList = res.data.data
					this.$nextTick(() => {
						this.loading = false;
					})
				}
			})
		},
		
		initData() {
			getDesignMenu(2185).then((res) => {
				this.menuList = res.data.data;
			})
			getBanner({position: 2193, cate: 2194}).then((res) => {
				this.bannerList = res.data;
			})
			getBanner({position: 2193, cate: 2196}).then((res) => {
				this.sideAD = res.data;
			})
		},
  },
  watch: {
    getListParams: {
      handler: function () {
        this.loading = true;
				this.getList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.xiding {
  position: sticky;
  top: 1.54rem;
}
	.competitionBox {
		position: relative;
		background: rgba(247, 249, 250, 1)
	}
	.midBox {
		width: 100%;
		padding: 0.1rem 1.2rem;
		display: flex;
		justify-content: space-between;
	}
	.contentBox {
		flex: 1;
		padding-right: 0.4rem;
	}
	.rightBox {
		width: 3.6rem;
	}
	
</style>
