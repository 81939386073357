<!--分类筛选组件-->
<template>
  <div class="ContBox">
		<div class="classifyBox" :class="{ isFixe: !tagBarFixed }">
			<div class="classifyChild">
				<div
					class="classifyChildItem"
					:class="{ active: !getListParams.id }"
					@click="getChildMenu()"
					>
					全部赛事
				</div>
				<div
					class="classifyChildItem"
					:class="{ active: getListParams.id == item.parameter }"
					v-for="(item, index) in menuList"
					:key="index"
					@click="getChildMenu(item.parameter)"
					>
					{{ item.name }}
				</div>
			</div>
			<div class="time">
				<div :class="{pickerDate: !isUpAdmin}">
					<el-date-picker
						v-model="value"
						type="daterange"
						value-format="timestamp"
						:picker-options="pickerOptions"
						@change="pickerDates"
						unlink-panels
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right">
					</el-date-picker>
				</div>
				<router-link 
					v-if="isUpAdmin"
					:to="{ path: '/competition/uploadCompetition' }"
				>
					<div class="addBtn">添加竞赛</div>
				</router-link>
			</div>
		</div>
		<div class="classifyBox" :class="{ isFixe1: tagBarFixed }">
			<div class="classifyChild">
				<div
					class="classifyChildItem"
					:class="{ active: !getListParams.id}"
					@click="getChildMenu()"
					>
					全部赛事
				</div>
				<div
					class="classifyChildItem"
					:class="{ active: getListParams.id == item.parameter }"
					v-for="(item, index) in menuList"
					:key="index"
					@click="getChildMenu(item.parameter)"
					>
					{{ item.name }}
				</div>
			</div>
			<div class="time">
				<div :class="{pickerDate: !isUpAdmin}">
					<el-date-picker
						v-model="value"
						type="daterange"
						value-format="timestamp"
						:picker-options="pickerOptions"
						@change="pickerDates"
						unlink-panels
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right">
					</el-date-picker>
				</div>
				<router-link 
					v-if="isUpAdmin"
					:to="{ path: '/competition/uploadCompetition' }"
				>
					<div class="addBtn">添加竞赛</div>
				</router-link>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: "CompetitionClassifyMenu",
  props: ["getListParams", "menuList", "isUpAdmin"],
  data() {
    return {
			value: '',
			tagBarFixed: false,
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
		}
  },
	created() {
		if(this.$route.query.start_time && this.$route.query.end_time) {
			this.value = [this.$route.query.start_time*1000, this.$route.query.end_time*1000]
		}
		let ListParams = {
				id: '',
				page: 0,
				per_page: 8,
				key: '',
				start_time: '',
				end_time: ''
			}
		if(this.$route.query.child1) {
			ListParams.id = this.$route.query.child1
		}
		if(this.$route.query.search) {
			ListParams.key = this.$route.query.search
		}
		if(this.$route.query.start_time) {
			ListParams.start_time = this.$route.query.start_time
		}
		if(this.$route.query.end_time) {
			ListParams.end_time = this.$route.query.end_time
		}
		this.$emit("changeMenu", ListParams)
	},
	methods: {
		pickerDates(res) {
			const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
			let listParams = JSON.parse(JSON.stringify(this.getListParams))
			if (res&&[0]) {
				listParams = {...listParams, ...{start_time: Math.floor(res[0]/1000), end_time: Math.floor(res[1]/1000)}}
				query.start_time = Math.floor(res[0]/1000);
				query.end_time = Math.floor(res[1]/1000);
			} else{
				listParams = {...listParams, ...{start_time: '', end_time: ''}}
				query.start_time = '';
				query.end_time = '';
			}
			this.$emit("changeMenu", listParams)
      this.$router.push({ path: this.$route.path, query });
		},
		getChildMenu(id) {
			let listParams = JSON.parse(JSON.stringify(this.getListParams))
			listParams = {...listParams, ...{id: id}}
			this.$emit("changeMenu", listParams)
      //将参数设置到地址栏
      const query = JSON.parse(JSON.stringify(this.$route.query)); // 获取路由参数信息
			query.child1 = id;
      this.$router.push({ path: this.$route.path, query });
    },
		handleScroll() {
			const scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			const offsetTop = document.querySelector(".ContBox").offsetTop;
			if (scrollTop > offsetTop) {
				this.tagBarFixed = true;
			} else {
				this.tagBarFixed = false;
			}
		},
	},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
  },
};
</script>

<style scoped>
	.ContBox {
		width: 100%;
		min-width: 12rem;
	}
	.classifyBox {
		height: 0;
		visibility: hidden;
		opacity: 0;
		transition: 0.5s;
		padding: 0 1.2rem;
		display: flex;
		justify-content: space-between;
		align-content: center;
	}
	.classifyBox.isFixe {
		height: 1rem;
		visibility: visible;
		opacity: 1;
		padding: 0.3rem 1.2rem;
	}
	.classifyBox.isFixe1 {
		height: 0.7rem;
		visibility: visible;
		opacity: 1;
		padding: 0.15rem 1.2rem;
		background: #ffffff;
		position: fixed;
		top: 0.68rem;
		width: 100%;
		min-width: 14rem;
		z-index: 8;
	}
	.classifyChild {
		flex: 1;
		display: flex;
		align-content: center;
	}
	.classifyChildItem {
		height: 0.4rem;
		padding: 0.1rem 0.25rem;
		border-radius: 0.2rem;
		font-size: 0.14rem;
		font-weight: 400;
		color: rgba(144, 147, 153, 1);
		border: 1px solid rgba(229, 229, 229, 1);
		margin-right: 0.32rem;
		cursor: pointer;
		transition: 0.3s;
		white-space: nowrap;
	}
	.classifyChildItem:hover {
		color: rgba(244, 102, 0, 1);
		background: rgba(253, 239, 229, 1);
		border: 1px solid rgba(253, 239, 229, 1);
		transform: translateY(-2px);
	}
	.classifyChildItem.active {
		color: rgba(244, 102, 0, 1);
		background: rgba(253, 239, 229, 1);
		border: 1px solid rgba(253, 239, 229, 1);
	}
	.time {
		width: 3.6rem;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.pickerDate {
		margin: 0 auto;
	}
	.addBtn {
		width: 1.06rem;
		height: 0.4rem;
		line-height: 0.4rem;
		border-radius: 0.2rem;
		background: rgba(244, 102, 0, 1);
		font-size: 0.14rem;
		font-weight: 500;
		text-align: center;
		color: rgba(255, 255, 255, 1);
	}
	/deep/.time .el-date-editor .el-range-input {
		font-size: 0.12rem;
	}
	/deep/.time .el-date-editor .el-range-separator {
		height: auto;
		display: flex;
		justify-content: center;
		align-content: center;
		padding: 0;
		font-size: 0.12rem;
	}
	/deep/.time .el-input__inner {
		width: 2.4rem;
		height: 0.4rem;
		line-height: 0.4rem;
		font-size: 0.12rem;
		border-radius: 20px;
		display: flex;
		justify-content: space-between;
	}
	/deep/.time .el-input__icon.el-range__close-icon {
		width: 0.2rem;
	}
	/deep/.time .el-input__icon.el-range__icon.el-icon-date {
		width: 0.2rem;
	}
	/deep/.time .el-input__icon {
		height: auto;
	}
</style>